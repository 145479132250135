// Import the functions you need from the Firebase SDK
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBg6GyfNVqwfxx1lo961QBhML7fJG6Ni9Y",
  authDomain: "saudichamber-9311c.firebaseapp.com",
  projectId: "saudichamber-9311c",
  storageBucket: "saudichamber-9311c.appspot.com",
  messagingSenderId: "663778982556",
  appId: "1:663778982556:web:e0ccd643edcf8060110b59",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firestore and export it
const db = getFirestore(app);
export { db };
