

import featureImage from "../../images/feature-image.png";

import zwy from "../../images/zwy.png";
import jaddaColoredLogo from "../../images/jadda-colored-logo.svg";
import ghi from "../../images/ghi.png";
import abc from "../../images/abc.png";
import macaColoredLogo from "../../images/maca-colored-logo.png";
import jkl from "../../images/jkl.png";
import sharkiaLogoColored from "../../images/sharkia-Logo-colored.png";
import defImg from "../../images/def.png";

import mno from "../../images/mno.png";
const ThirdPart = () => {
  const svgPath = (
    <path
      className="path"
      d="M12 0C9.62663 0 7.30655 0.703788 5.33316 2.02236C3.35977 3.34094 1.8217 5.21509 0.913451 7.4078C0.00519941 9.60051 -0.232441 12.0133 0.230582 14.3411C0.693605 16.6689 1.83649 18.807 3.51472 20.4853C5.19295 22.1635 7.33115 23.3064 9.65892 23.7694C11.9867 24.2324 14.3995 23.9948 16.5922 23.0865C18.7849 22.1783 20.6591 20.6402 21.9776 18.6668C23.2962 16.6934 24 14.3734 24 12C23.9961 8.8186 22.7306 5.76862 20.481 3.51903C18.2314 1.26945 15.1814 0.00390836 12 0ZM18.4135 9.88615L13.0505 17.0382C12.7722 17.4087 12.4176 17.715 12.0106 17.9365C11.6036 18.158 11.1537 18.2893 10.6915 18.3217C10.2293 18.3541 9.76552 18.2868 9.33161 18.1242C8.8977 17.9617 8.50381 17.7078 8.17662 17.3797L5.40739 14.6105C5.06122 14.264 4.86684 13.7943 4.86701 13.3046C4.8671 13.0621 4.91495 12.822 5.00782 12.598C5.1007 12.374 5.23678 12.1705 5.40831 11.9991C5.57984 11.8277 5.78345 11.6917 6.00751 11.599C6.23157 11.5063 6.4717 11.4586 6.71419 11.4587C7.20392 11.4589 7.67353 11.6536 8.0197 12L10.0394 14.0197C10.0861 14.0666 10.1423 14.1029 10.2042 14.1262C10.2661 14.1495 10.3323 14.1592 10.3983 14.1546C10.4644 14.1501 10.5286 14.1314 10.5868 14.0998C10.6449 14.0683 10.6956 14.0246 10.7354 13.9717L15.4597 7.67077C15.7535 7.27906 16.1908 7.02011 16.6755 6.95086C16.9155 6.91658 17.1599 6.9299 17.3948 6.99007C17.6297 7.05023 17.8504 7.15607 18.0443 7.30154C18.2383 7.447 18.4017 7.62924 18.5252 7.83786C18.6487 8.04647 18.7299 8.27737 18.7642 8.51737C18.7985 8.75738 18.7852 9.00179 18.725 9.23664C18.6648 9.4715 18.559 9.6922 18.4135 9.88615Z"
    ></path>
  );

  const Card = ({ title }) => (
    <div className="card card-3">
      <div className="card-body card-body-3">
        <div className="round">
          <div className="item-icon">
            <svg
              className="svg-rod"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              {svgPath}
            </svg>
          </div>
        </div>
        <div className="card-title">{title}</div>
      </div>
    </div>
  );

  return (
    <div className="third-part">
      <div className="upper-3rd">
        <div className="left-3rd">
          <img src={featureImage} alt="fmi" className="fmi" />
        </div>
        <div className="right-3rd">
          <p className="text-p">خدمات الغرف الذكية</p>
          <h3 className="h3-3rd">
            مميزات استخدامك لبوابة الموحدة للغرف التجارية
          </h3>
          <div className="cards card-sect-3">
            <Card title="توفير الجهد و الوقت من خلال انهاء طلبات منشآتك بشكل رقمي" />
            <Card title="متابعة عمليات كل منشآتك" />
            <Card title="تعيين مفوضين بشكل سلس و متابعة تحركاتهم" />
          </div>
        </div>
      </div>
      <div className="lower-3rd">
        <div className="blue-sect">
          <div className="upper-blue-sect">
            <div className="left-lower-3"></div>
            <div className="right-lower-3" style={{ textAlign: "right" }}>
              <h6>أعضاء الإتحاد</h6>
              <h3>قائمة الغرف التجارية</h3>
            </div>
          </div>
          <div className="lower-blue-sect">
            <div className="grid-container">
              <div className="grid-item">
                <img src={zwy} className="img-grid" alt="Zwy" />
              </div>
              <div className="grid-item">
                <img
                  src={jaddaColoredLogo}
                  className="img-grid-1 img-grid-2"
                  alt="Jadda Colored Logo"
                />
              </div>
              <div className="grid-item">
                <img src={ghi} className="img-grid-1" alt="GHI" />
              </div>
              <div className="grid-item">
                <img src={abc} className="img-grid-1" alt="ABC" />
              </div>
              <div className="grid-item">
                <img
                  src={macaColoredLogo}
                  className="img-grid-1 img-grid-3"
                  alt="Maca Colored Logo"
                />
              </div>
              <div className="grid-item">
                <img src={jkl} className="img-grid-1" alt="JKL" />
              </div>
              <div className="grid-item">
                <img
                  src={sharkiaLogoColored}
                  className="img-grid-1 img-grid-4"
                  alt="Sharkia Logo Colored"
                />
              </div>
              <div className="grid-item">
                <img src={defImg} className="img-grid-1" alt="DEF" />
              </div>
              <div className="grid-item">
                <img src={mno} className="img-grid-1" alt="MNO" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThirdPart;
