// import React, { useEffect, useState, useRef } from "react";
// import "../verificationDetailPage/Verificationdetailpage.css";
// import { useLocation } from "react-router-dom";
// import { doc, getDoc } from "firebase/firestore";
// import { db } from "../../firebase";
// import { Sheet } from "react-modal-sheet";
// function Verificationdetailpage() {
//   const [isOpen, setOpen] = useState(false);
//   const location = useLocation();
//   const [docData, setDocData] = useState(null);
//   const [error, setError] = useState(null);
//   const queryParams = new URLSearchParams(location.search);
//   const docId = queryParams.get("id");

//   const sheetRef = useRef(null); // Create a ref for the sheet
//   useEffect(() => {
//     window.addEventListener("resize", handleResize);
//     return () => window.removeEventListener("resize", handleResize);
//   }, []);

//   useEffect(() => {
//     if (docId) {
//       const fetchData = async () => {
//         try {
//           const docRef = doc(db, "message", docId);
//           const docSnap = await getDoc(docRef);

//           if (docSnap.exists()) {
//             setDocData(docSnap.data());
//             setError(null); // Clear error if the document exists
//           } else {
//             setDocData(null); // Set docData to null if the document does not exist
//             setError("Document does not exist!"); // Set the error message
//           }
//         } catch (error) {
//           console.error("Error fetching document: ", error);
//           setError("Error fetching document"); // Handle the case of a fetch error
//         }
//       };

//       fetchData();
//     }
//   }, [docId]);

//   const formatDate = (timestamp) => {
//     if (timestamp) {
//       const date = timestamp.toDate();
//       return date.toLocaleDateString(); // Format date as needed
//     }
//     return "N/A";
//   };

//   const handleResize = () => {
//     console.log("Viewport width: " + window.innerWidth);
//     console.log("Viewport height: " + window.innerHeight);
//   };

//   return (
//     <div className="body">
//       <div className="header trigger">
//         <img src="newHeader.PNG" alt="header" className="left-img trigger" />
//         <img src="loader.png" alt="loader" className="right-img trigger" />
//       </div>

//       {/* Check for the presence of error or docData */}
//       {error ? (
//         <div
//           className="error-message"
//           style={{ color: "red", textAlign: "center" }}
//         >
//           <p>{error}</p>
//         </div>
//       ) : docData ? (
//         <div className="info" id="infoo">
//           <div className="info-0" style={{ marginTop: "2.5rem" }}>
//             <div className="heading">
//               <span className="bold">مقدم الطلب</span>
//             </div>
//             <div className="heading-data space">{docData.applicant}</div>
//           </div>
//           <div className="info-1">
//             <div className="heading">
//               <span className="bold">رقم الإشتراك</span>
//             </div>
//             <div className="heading-data">{docData.subscription_number}</div>
//           </div>
//           <div className="info-2" style={{ marginRight: "-44px" }}>
//             <div className="heading">
//               <span className="bold">رقم السجل التجاري</span>
//             </div>
//             <div className="heading-data">
//               {docData.commercial_registration_number}
//             </div>
//           </div>
//           <div className="info-3" style={{ marginRight: "2rem" }}>
//             <div className="heading">
//               <span className="bold">اسم المنشأة</span>
//             </div>
//             <div className="heading-data" style={{ width: "138px" }}>
//               {docData.facility_name}
//             </div>
//           </div>
//           <div className="info-4">
//             <div className="heading">
//               <span className="bold">حالة الوثيقة</span>
//             </div>
//             <div className="heading-data">{docData.document_status}</div>
//           </div>
//           <div className="info-5" style={{ marginRight: "1.75rem" }}>
//             <div className="heading">
//               <span className="bold">تاريخ الإنشاء</span>
//             </div>
//             <div className="heading-data">
//               {formatDate(docData.date_of_creation)}
//             </div>
//           </div>
//           <div className="info-6" style={{ marginRight: "0.75rem" }}>
//             <div className="heading">
//               <span className="bold">رقم الوثيقة</span>
//             </div>
//             <div className="heading-data">{docData.document_number}</div>
//           </div>
//         </div>
//       ) : (
//         <p>Loading...</p>
//       )}

//       {docData?.document_pdf_link && (
//         <div
//           className="letter"
//           style={{ width: "100vw", height: "100vh", overflow: "hidden" }}
//         >
//           <iframe
//             src={docData.document_pdf_link}
//             frameBorder="0"
//             title="Document PDF"
//             style={{ width: "100%", height: "100%" }}
//             allowFullScreen
//           ></iframe>{" "}
//           <button className="info-button" onClick={() => setOpen(true)}>
//             <i className="fas fa-info-circle"></i>
//           </button>
//           <img src="s.PNG" className="s" alt="Special Icon" />
//           <Sheet
//             disableDrag
//             ref={sheetRef} // Use the ref here
//             isOpen={isOpen}
//             onClose={() => setOpen(false)}
//             snapPoints={[
//               window.innerHeight * 0.5,
//               window.innerHeight * 0.75,
//               window.innerHeight,
//             ]} // Snap points based on viewport height
//             initialSnap={0} // Start at the first snap point
//           >
//             <Sheet.Container>
//               <Sheet.Content>
//                 <div className="document-info-container">
//                   <div className="info-cont">
//                     <div className="lower-part">
//                       <i className="cx">
//                         <img
//                           onClick={() => setOpen(false)}
//                           src="cancel.png"
//                           className="original-image"
//                           alt="Cancel Icon"
//                         />
//                         {/* <img
//                             onClick={() => setOpen(false)}
//                             src="cancel(1).png"
//                             className="hover-image"
//                             alt="Cancel Icon Hover"
//                           /> */}
//                       </i>
//                     </div>
//                     <div className="upper-part" id="content-to-copy">
//                       <div className="container">
//                         <p className="m2 bold">معلومات الوثيقة</p>
//                       </div>
//                     </div>
//                   </div>
//                   <hr />
//                   <div className="scrollable-raqamulwaseeqa" dir="rtl">
//                     {" "}
//                     {/* Apply RTL here */}
//                     <div className="heading">
//                       <p className="upper-h" style={{ marginTop: "1rem" }}>
//                         رقم الوثيقة
//                       </p>
//                       <div className="lower-h">
//                         <div className="heading-data regular">
//                           {docData.document_number}
//                         </div>
//                       </div>
//                     </div>
//                     <div className="heading">
//                       <p className="upper-h">تاريخ الإنشاء</p>
//                       <div className="lower-h">
//                         <div className="heading-data regular">
//                           <div
//                             className="heading-data trk"
//                             style={{ width: "200px" }}
//                           >
//                             {formatDate(docData.date_of_creation)}
//                           </div>
//                         </div>
//                       </div>
//                     </div>
//                     <div className="heading">
//                       <p className="upper-h">حالة الوثيقة</p>
//                       <div className="lower-h">
//                         <div className="heading-data regular">
//                           {docData.document_status}
//                         </div>
//                       </div>
//                     </div>
//                     <div className="heading">
//                       <p className="upper-h">اسم المنشأة</p>
//                       <div className="lower-h">
//                         <div
//                           className="heading-data regular"
//                           style={{ width: "138px" }}
//                         >
//                           {docData.facility_name}
//                         </div>
//                       </div>
//                     </div>
//                     <div className="heading">
//                       <p className="upper-h">رقم السجل التجاري</p>
//                       <div className="lower-h">
//                         <div className="heading-data regular">
//                           {" "}
//                           {docData.commercial_registration_number}
//                         </div>
//                       </div>
//                     </div>
//                     <div className="heading">
//                       <p className="upper-h">رقم الإشتراك</p>
//                       <div className="lower-h">
//                         <div className="heading-data regular">
//                           {docData.subscription_number}
//                         </div>
//                       </div>
//                     </div>
//                     <div className="heading">
//                       <p className="upper-h">مقدم الطلب</p>
//                       <div className="lower-h">
//                         <div className="heading-data regular">
//                           {docData.applicant}
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </Sheet.Content>
//             </Sheet.Container>
//           </Sheet>
//         </div>
//       )}
//     </div>
//   );
// }

// export default Verificationdetailpage;
import React, { useEffect, useState, useRef } from "react";
import "../verificationDetailPage/Verificationdetailpage.css";
import { useLocation } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase";
import { Sheet } from "react-modal-sheet";

function Verificationdetailpage() {
  const [isOpen, setOpen] = useState(false);
  const location = useLocation();
  const [docData, setDocData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true); // Track loading state
  const queryParams = new URLSearchParams(location.search);
  const docId = queryParams.get("_id");
  const [minimumLoaderTimePassed, setMinimumLoaderTimePassed] = useState(false); // Track if 3 seconds have passed

  const sheetRef = useRef(null); // Create a ref for the sheet

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    // Simulate a minimum 3-second loading time
    const timer = setTimeout(() => {
      setMinimumLoaderTimePassed(true);
    }, 3000); // 3 seconds

    if (docId) {
      const fetchData = async () => {
        try {
          const docRef = doc(db, "DocumentData", docId);
          const docSnap = await getDoc(docRef);

          if (docSnap.exists()) {
            setDocData(docSnap.data());
            setError(null); // Clear error if the document exists
          } else {
            setDocData(null); // Set docData to null if the document does not exist
            setError("Document does not exist!"); // Set the error message
          }
        } catch (error) {
          console.error("Error fetching document: ", error);
          setError("Error fetching document"); // Handle the case of a fetch error
        } finally {
          // Stop loading if data is fetched and 3 seconds have passed
          if (minimumLoaderTimePassed) {
            setLoading(false);
          }
        }
      };

      fetchData();
    }

    // Ensure the loader is shown for at least 3 seconds before hiding
    return () => clearTimeout(timer);
  }, [docId, minimumLoaderTimePassed]);

  useEffect(() => {
    if (docData && minimumLoaderTimePassed) {
      // Hide the loader when data is loaded and 3 seconds have passed
      setLoading(false);
    }
  }, [docData, minimumLoaderTimePassed]);

  const formatDate = (timestamp) => {
    if (timestamp) {
      const date = timestamp.toDate();
      return date.toLocaleDateString(); // Format date as needed
    }
    return "N/A";
  };

  const handleResize = () => {
    console.log("Viewport width: " + window.innerWidth);
    console.log("Viewport height: " + window.innerHeight);
  };

  // Show loader while data is loading
  if (loading) {
    return (
      <div id="loader">
        <div className="loader-container">
          <div></div>
          <div></div>
          <img src="loader.png" alt="Loading..." className="loader" />
        </div>
      </div>
    );
  }

  return (
    <div className="body">
      <div className="header triggerr">
        <img src="newHeader.PNG" alt="header" className="left-img trigger" />
        <img src="loader.png" alt="loader" className="right-img trigger" />
      </div>

      {/* Check for the presence of error or docData */}
      {error ? (
        <div
          className="error-message"
          style={{ color: "red", textAlign: "center" }}
        >
          <p>{error}</p>
        </div>
      ) : docData ? (
        <div className="info" id="infoo">
          <div className="info-0" style={{ marginTop: "2.5rem" }}>
            <div className="heading">
              <span className="bold">مقدم الطلب</span>
            </div>
            <div className="heading-data space">{docData.applicant}</div>
          </div>
          <div className="info-1">
            <div className="heading">
              <span className="bold">رقم الإشتراك</span>
            </div>
            <div className="heading-data">{docData.subscription_number}</div>
          </div>
          <div className="info-2" style={{ marginRight: "-44px" }}>
            <div className="heading">
              <span className="bold">رقم السجل التجاري</span>
            </div>
            <div className="heading-data">
              {docData.commercial_registration_number}
            </div>
          </div>
          <div className="info-3" style={{ marginRight: "2rem" }}>
            <div className="heading">
              <span className="bold">اسم المنشأة</span>
            </div>
            <div className="heading-data" style={{ width: "138px" }}>
              {docData.facility_name}
            </div>
          </div>
          <div className="info-4">
            <div className="heading">
              <span className="bold">حالة الوثيقة</span>
            </div>
            <div className="heading-data">{docData.document_status}</div>
          </div>
          <div className="info-5" style={{ marginRight: "1.75rem" }}>
            <div className="heading">
              <span className="bold">تاريخ الإنشاء</span>
            </div>
            <div className="heading-data">{docData.date_of_creation}</div>
          </div>
          <div className="info-6" style={{ marginRight: "0.75rem" }}>
            <div className="heading">
              <span className="bold">رقم الوثيقة</span>
            </div>
            <div className="heading-data space">{docData.document_number}</div>
          </div>
        </div>
      ) : (
        <p>Loading...</p>
      )}

      {docData?.document_pdf_link && (
        <div
          className="letter"
          style={{ width: "100vw", height: "100vh", overflow: "hidden" }}
        >
          <iframe
            src={docData.document_pdf_link}
            frameBorder="0"
            title="Document PDF"
            style={{ width: "100%", height: "100%" }}
            allowFullScreen
          ></iframe>
          <button className="info-button" onClick={() => setOpen(true)}>
            <i className="fas fa-info-circle"></i>
          </button>
          <img src="s.PNG" className="s" alt="Special Icon" />
          <Sheet
            disableDrag
            ref={sheetRef} // Use the ref here
            isOpen={isOpen}
            onClose={() => setOpen(false)}
            snapPoints={[
              window.innerHeight * 0.7,
              window.innerHeight * 0.75,
              window.innerHeight,
            ]} // Snap points based on viewport height
            initialSnap={0} // Start at the first snap point
          >
            <Sheet.Container>
              <Sheet.Content>
                <div className="document-info-container">
                  <div className="info-cont">
                    <div className="lower-part">
                      <i className="cx">
                        <img
                          onClick={() => setOpen(false)}
                          src="cancel.png"
                          className="original-image"
                          alt="Cancel Icon"
                        />
                      </i>
                    </div>
                    <div className="upper-part" id="content-to-copy">
                      <div className="container">
                        <p className="m2 bold">معلومات الوثيقة</p>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className="scrollable-raqamulwaseeqa" dir="rtl">
                    <div className="heading">
                      <p className="upper-h" style={{ marginTop: "1rem" }}>
                        رقم الوثيقة
                      </p>
                      <div className="lower-h">
                        <div className="heading-data regular">
                          {docData.document_number}
                        </div>
                      </div>
                    </div>
                    <div className="heading">
                      <p className="upper-h">تاريخ الإنشاء</p>
                      <div className="lower-h">
                        <div className="heading-data regular">
                          <div
                            className="heading-data trk"
                            style={{ width: "200px" }}
                          >
                            {docData.date_of_creation}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="heading">
                      <p className="upper-h">حالة الوثيقة</p>
                      <div className="lower-h">
                        <div className="heading-data regular">
                          {docData.document_status}
                        </div>
                      </div>
                    </div>
                    <div className="heading">
                      <p className="upper-h">اسم المنشأة</p>
                      <div className="lower-h">
                        <div
                          className="heading-data regular"
                          style={{ width: "138px" }}
                        >
                          {docData.facility_name}
                        </div>
                      </div>
                    </div>
                    <div className="heading">
                      <p className="upper-h">رقم السجل التجاري</p>
                      <div className="lower-h">
                        <div className="heading-data regular">
                          {docData.commercial_registration_number}
                        </div>
                      </div>
                    </div>
                    <div className="heading">
                      <p className="upper-h">رقم الإشتراك</p>
                      <div className="lower-h">
                        <div className="heading-data regular">
                          {docData.subscription_number}
                        </div>
                      </div>
                    </div>
                    <div className="heading">
                      <p className="upper-h">مقدم الطلب</p>
                      <div className="lower-h">
                        <div className="heading-data regular">
                          {docData.applicant}
                        </div>
                      </div>
                    </div>{" "}
                    <div className="heading">
                      <p className="upper-h"></p>
                      <div className="lower-h">
                        <div className="heading-data regular"></div>
                      </div>
                    </div>
                    <div className="heading">
                      <p className="upper-h"></p>
                      <div className="lower-h">
                        <div className="heading-data regular"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </Sheet.Content>
            </Sheet.Container>
          </Sheet>
        </div>
      )}
    </div>
  );
}

export default Verificationdetailpage;
