import React from "react";
import maintenanceImage from "../../images/web-maintenance.png";
import Navbar from "../navbar/navbar";
const NewRegistration = () => {
  // return (
  //   <div className="body">
  //     <div className="registration-left">
  //       <h1>تسجيل جديد</h1>
  //       <div className="input-group">
  //         <label>نوع التواصل</label>
  //         <div style={{ marginLeft: "20rem" }}>
  //           <div className="col form-group select-box">
  //             <label
  //               className="label required"
  //               style={{ width: "12.5rem" }}
  //             ></label>
  //             <select
  //               className="form-control"
  //               style={{
  //                 border: "1px solid #1a1b1d",
  //                 width: "35rem",
  //                 textAlign: "right",
  //                 backgroundColor: "#fff",
  //               }}
  //             >
  //               <option value="" selected disabled>
  //                 اختر نوع التواصل
  //               </option>
  //               <option value="1">اقتراح</option>
  //               <option value="2">شكوى</option>
  //               <option value="3">استفسار</option>
  //               <option value="4">أخرى</option>
  //             </select>
  //           </div>
  //         </div>
  //       </div>

  //       <div className="input-group">
  //         <label>رقم الهوية</label>
  //         <div style={{ marginLeft: "20rem" }}>
  //           <div className="mb-3">
  //             <input
  //               style={{ border: "1px solid black" }}
  //               type="text"
  //               placeholder="أدخل رقم الهوية"
  //               className="form-control"
  //             />
  //           </div>
  //         </div>
  //       </div>

  //       <button className="submit-button btn">تأكيد</button>
  //     </div>

  //     <div className="registration-right">
  //       <img src={rightSlideImg} alt="Right Slide" width="100%" />
  //     </div>
  //   </div>
  // );
  //};

  return (
    <>
      <Navbar />
      <div style={styles.body}>
        <article style={styles.article}>
          <h1 style={styles.h1}>This Page is temporarily unavailable.</h1>
          <p>
            Scheduled maintenance is currently in progress. Please check back
            soon.
          </p>
          <p>We apologize for any inconvenience.</p>
        </article>
      </div>
    </>
  );
};

// Styles as JavaScript object to inline them into JSX
const styles = {
  body: {
    textAlign: "center",
    padding: "10%",
    fontFamily: "Helvetica, sans-serif",
    color: "#333",
    fontSize: "20px",
  },
  h1: {
    fontSize: "50px",
    margin: "0",
    // Responsive style
    "@media only screen and (max-width: 480px)": {
      fontSize: "40px",
    },
  },
  article: {
    display: "block",
    textAlign: "left",
    maxWidth: "650px",
    margin: "0 auto",
  },
  link: {
    color: "#dc8100",
    textDecoration: "none",
  },
};

export default NewRegistration;
