import React from "react";
import Footer from "../homePage/footer";
import Navbar from "../navbar/navbar";
import "./faq.css";
const Faq = () => {
  return (
    <div>
      <Navbar />
      <main>
        <div className="faq-upper-header">
          <h1>الأسئلة الشائعة</h1>
          <div className="faq-path">
            <p>
              <b>الرئيسية</b> الأسئلة الشائعة
              <i className="fa-sharp fa-solid fa-house"></i>
            </p>
          </div>
        </div>
        <div className="faq-main-content">
          <h2 style={{ marginBottom: "-32px" }}>الأسئلة الشائعة</h2>
          <b style={{ visibility: "hidden", height: 0 }}>
            1- كيف يمكنني التسجيل في المنصة؟
          </b>
          <b style={{ visibility: "hidden", height: 0 }}>
            <p>
              يمكنك التسجيل في المنصة عن طريق النفاذ الموحد أو التسجيل اليدوي
              بإدخال بياناتك وتقوم الغرفة بتفعيل حسابك بعد التحقق من صحة
              بياناتك.
            </p>
          </b>
          <b>1- كيف يمكنني التسجيل في المنصة؟</b>
          <b>
            <p>
              يمكنك التسجيل في المنصة عن طريق النفاذ الموحد أو التسجيل اليدوي
              بإدخال بياناتك وتقوم الغرفة بتفعيل حسابك بعد التحقق من صحة
              بياناتك.
            </p>
          </b>
          <b>2- لم أستطع الدخول لحسابي في المنصة؟</b>
          <b>
            <p>
              يمكن طلب استعادة كلمة المرور وتغيير كلمة المرور أو التقدم بطلب
              للدعم الفني في حال كنت تواجه مشكلة.
            </p>
          </b>
          <b>3- ماهي الخدمات المقدمة عن طريق منصة اتحاد الغرف التجارية؟</b>
          <b>
            <p>
              جميع الخدمات التي تقدمها الغرف التجارية في المملكة العربية
              السعودية مثل تصديق الوثائق، تصاريح المسابقات والعروض الترويجية
              وغيرها.
            </p>
          </b>
          <b>4- كيف يمكنني الاستفادة من الخدمات؟</b>
          <b>
            <p>
              بعد إنشاء الحساب قم باستكمال بيانات منشأتك أو إضافة منشأتك في حال
              لم تكن لديك عضوية في وزارة التجارة
            </p>
          </b>
        </div>
      </main>

      <Footer />

      <script src="/js/script.js"></script>
    </div>
  );
};

export default Faq;
