import React from "react";
import fsclogo from "../../images/FSC-logo.png";
const Footer = () => {
  return (
    <div className="footer">
      <div className="upper-footer">
        <div className="sect-1-upper">
          <a href="/usage-guides">أدلة الإستخدام &lt;</a>
        </div>
        <div className="sect-2-upper">
          <a href="/faq">الأسئلة الشائعة &lt;</a>
        </div>
        <div className="sect-3-upper">
          <a href="/about">عن المنصة &lt;</a>
          <a href="/contact-us">تواصل معنا &lt;</a>
        </div>
        <div className="sect-4-upper">
          <img src={fsclogo} className="f-img" alt="FSC Logo" />
        </div>
      </div>
      <hr style={{ color: "#6e7178" }} />
      <div className="lower-footer">
        <div className="left-lowest">© موقع اتحاد الغرف السعودية 2023</div>
        <div className="right-lowest">الشروط والأحكام سياسة الخصوصية</div>
      </div>
    </div>
  );
};

export default Footer;
