import React from "react";
import ThirdPart from "./thirdparth";
import "../../Components/homePage/home.css";
import featureimage from "../../images/feature-image.png";
import companyLogo from "../../images/companyLogo.png";
import iconuser from "../../images/icon-user.png";
import heroformicon from "../../images/hero-form-icon1.png";

import { Link, useLocation } from "react-router-dom";
import t1 from "../../images/t1.png";
import t2 from "../../images/t2.png";
import t3 from "../../images/t3.png";
import t4 from "../../images/t4.png";
import t5 from "../../images/t5.png";
import icondoc from "../../images/icon-document.png";
import herobg from "../../images/hero-bg.png";
import "@fortawesome/fontawesome-free/css/all.min.css";
import FourthPart from "./fourthpath";
import Footer from "./footer";
import Navbar from "../navbar/navbar";
function HomeMain() {
  return (
    <div>
      <Navbar />
      <main>
        <h1 className="main-heading">
          استمتع <span className="e-services">بخدمات</span> إلكترونية لتيسير
          أعمال منشآتك
        </h1>
        <p className="main-desc">
          منصة إلكترونية وطنية متكاملة تحت رعاية اتحاد الغرف التجارية، تتيح
          للغرف تقديم خدماتها عبر الإنترنت لأعضائها، حيث تشارك حاليًا فيها غرفتا
          جدة والشرقية فقط
        </p>
        <img
          src={herobg}
          alt=""
          style={{ height: "auto", width: "100vw", marginTop: "7rem" }}
        />

        <div className="main-box">
          <div className="upper-box">
            <span className="main-span">
              <b>الإستعلام عن الوثائق</b>
            </span>
            <i className="fa-solid fa-magnifying-glass"></i>
          </div>
          <hr className="box-hr" />
          <div className="lower-box">
            <div className="lower-search">
              <i className="fa-solid fa-magnifying-glass lower-search-icon"></i>
            </div>
            <div className="sect-1-lower">
              <div className="upper">
                <span className="span-sect-1-lower">*رقم الإشتراك</span>
                <input
                  type="text"
                  placeholder="ادخل الرقم"
                  className="form-control lower-cont"
                />
              </div>
              <div className="lower">
                <img src={iconuser} alt="icon-user" />
              </div>
            </div>
            <div className="sect-2-lower">
              <div className="upper">
                <span className="span-sect-2-lower">الإستعلام عن الوثائق</span>
                <input
                  type="text"
                  placeholder="ابحث برقم الوثيقة"
                  className="form-control lower-cont"
                  style={{ textAlign: "right" }}
                />
              </div>
              <div className="lower">
                <img src={icondoc} alt="icon-doc" />
              </div>
            </div>
            <div className="sect-3-lower">
              <div className="upper">
                <span className="span-sect-3-lower">*الغرفة</span>
                <select
                  name=""
                  id=""
                  className="form-control lower-cont"
                  style={{ width: "12.5rem", textAlign: "right" }}
                >
                  <option value="null" selected style={{ color: "black" }}>
                    اختر الغرفة
                  </option>
                  <option value="a0114355-c643-4c78-bc7e-d326595f7ade">
                    غرفة الشرقية
                  </option>
                  <option value="c0114355-c643-4c78-bc7e-d326595f7ade">
                    غرفة جدة
                  </option>
                </select>
              </div>
              <div className="lower">
                <img src={heroformicon} alt="hero-form-icon1" />
              </div>
            </div>
          </div>
        </div>

        <div className="second-main-box">
          <div className="upper-2nd-desc">
            <div className="text">
              <p className="text-p">خدمات التصديق للمنشآت وأكثر</p>
              <h2 className="text-h2">خدمات البوابة الموحدة للغرف التجارية</h2>
            </div>
          </div>

          <div className="cards">
            <div
              id="carouselExampleFade"
              className="carousel slide carousel-fade"
            >
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <img
                    src={t1}
                    className="d-block w-25 h-25"
                    style={{ marginLeft: "25rem" }}
                    alt="..."
                  />
                </div>
                <div className="carousel-item">
                  <img
                    src={t2}
                    className="d-block w-25 h-25"
                    style={{ marginLeft: "25rem" }}
                    alt="..."
                  />
                </div>
                <div className="carousel-item">
                  <img
                    src={t3}
                    className="d-block w-25 h-25"
                    style={{ marginLeft: "25rem" }}
                    alt="..."
                  />
                </div>
                <div className="carousel-item">
                  <img
                    src={t4}
                    className="d-block w-25 h-25"
                    style={{ marginLeft: "25rem" }}
                    alt="..."
                  />
                </div>
                <div className="carousel-item">
                  <img
                    src={t5}
                    className="d-block w-25 h-25"
                    style={{ marginLeft: "25rem" }}
                    alt="..."
                  />
                </div>
              </div>

              <button
                className="carousel-control-prev"
                type="button"
                data-bs-target="#carouselExampleFade"
                data-bs-slide="prev"
              >
                <i className="fa-solid fa-circle-arrow-left"></i>
                <span className="visually-hidden">Previous</span>
              </button>
              <button
                className="carousel-control-next"
                type="button"
                data-bs-target="#carouselExampleFade"
                data-bs-slide="next"
              >
                <i className="fa-solid fa-circle-arrow-right"></i>
                <span className="visually-hidden">Next</span>
              </button>
            </div>
          </div>
        </div>
      </main>
      <ThirdPart />
      <FourthPart />
      <Footer />
    </div>
  );
}

export default HomeMain;
