import React from "react";
import { Link } from "react-router-dom";
import companyLogo from "../../images/companyLogo.png"; // Ensure the path is correct
import "../homePage/home.css";

const Navbar = () => {
  return (
    <div>
      <header className="web-header web-header-2">
        <nav
          className="navbar navbar-dark"
          style={{
            backgroundColor: "#3f5fa0",
            marginLeft: "3rem",
            color: "white",
            border: "none",
          }}
        >
          <div className="container-fluid">
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarToggleExternalContent"
              aria-controls="navbarToggleExternalContent"
              aria-expanded="true"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
          </div>
        </nav>

        <div className="left" data-bs-theme="light">
          <Link className="btn btn-md entrance nav-but" to="/login">
            دخول
          </Link>

          <Link className="nav-anc" to="/contact-us">
            اتصل بنا
          </Link>
          <Link className="nav-anc" to="/faq">
            الأسئلة الشائعة
          </Link>
          <Link className="nav-anc" to="/usage-guides">
            أدلة الإستخدام
          </Link>
          <Link className="nav-anc" to="/about">
            عن المنصة
          </Link>
          <Link className="nav-anc" to="/">
            الرئيسية
          </Link>
        </div>

        <div className="right">
          <img src={companyLogo} alt="companyLogo" className="main-logo" />
        </div>
      </header>

      <div
        className="collapse"
        id="navbarToggleExternalContent"
        data-bs-theme="dark"
      >
        <div
          className="p-4"
          style={{
            display: "flex",
            flexDirection: "column-reverse",
            textAlign: "right",
          }}
        >
          {/* <Link className="btn btn-md entrance nav-but" to="/login">
            دخول
          </Link> */}
          <br />

          <Link className="nav-anc" to="/contact-us">
            اتصل بنا
          </Link>
          <Link className="nav-anc" to="/faq">
            الأسئلة الشائعة
          </Link>
          <Link className="nav-anc" to="/usage-guides">
            أدلة الإستخدام
          </Link>
          <Link className="nav-anc" to="/about">
            عن المنصة
          </Link>
          <Link className="nav-anc" to="/">
            الرئيسية
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
