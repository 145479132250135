import React from "react";
import Navbar from "../navbar/navbar";
import Footer from "../homePage/footer";
import addbalance from "../../images/add-balance.mp4";
import addcommissioner from "../../images/add-commissioner.mp4";
import addfacilitiy from "../../images/add-facilitiy.mp4";
import getcommercial from "../../images/get-commercial-permissions.mp4";
import managefacelities from "../../images/manage-facelities-serveice.mp4";
import "./userGuid.css";
const Guides = () => {
  return (
    <>
      <Navbar />
      <div className="main">
        <div className="about-upper-header">
          <h1> أدلة الإستخدام</h1>
          <div className="about-path">
            <p>
              <b>الرئيسية</b> أدلة الإستخدام
              <i className="fa-sharp fa-solid fa-house"></i>
            </p>
          </div>
        </div>

        <div className="guides-grid">
          <div className="guides-grid-item">
            <div className="guides-card">
              <video width="271" height="179" controls>
                <source src={addfacilitiy} type="video/mp4" />
              </video>
              <div className="guides-card-body">
                <h5 className="guides-card-title">
                  الفيديو التعريفي لـ طلب إضافة منشأة جديدة
                </h5>
              </div>
            </div>
          </div>

          <div className="guides-grid-item">
            <div className="guides-card">
              <video width="271" height="179" controls>
                <source src={addcommissioner} type="video/mp4" />
              </video>
              <div className="guides-card-body">
                <h5 className="guides-card-title">
                  الفيديو التعريفي لـ إضافة مفوض في منشأتك
                </h5>
              </div>
            </div>
          </div>

          <div className="guides-grid-item">
            <div className="guides-card">
              <video width="271" height="179" controls>
                <source src={addbalance} type="video/mp4" />
              </video>
              <div className="guides-card-body">
                <h5 className="guides-card-title">
                  الفيديو التعريفي لـ شحن رصيد محفظتك
                </h5>
              </div>
            </div>
          </div>

          <div className="guides-grid-item">
            <div className="guides-card">
              <video width="271" height="179" controls>
                <source src={getcommercial} type="video/mp4" />
              </video>
              <div className="guides-card-body">
                <h5 className="guides-card-title">
                  الفيديو التعريفي لخدمة إصدار تصاريح
                </h5>
              </div>
            </div>
          </div>

          <div className="guides-grid-item">
            <div className="guides-card">
              <video width="271" height="179" controls>
                <source src={managefacelities} type="video/mp4" />
              </video>
              <div className="guides-card-body">
                <h5 className="guides-card-title">
                  الفيديو التعريفي لـ خدمة إدارة الإشتراكات
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Guides;
