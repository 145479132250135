import "./App.css";
import Verificationdetailpage from "./Components/verificationDetailPage/Verificationdetailpage";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { useEffect } from "react";
import HomeMain from "./Components/homePage/home";
import Faq from "./Components/faqPage/faq";

import AboutUnion from "./Components/aboutPlatformPage/about";
import Guides from "./Components/userGuidPage/userGuid";
import ContactUs from "./Components/contactUs/contactUs";
import NewRegistration from "./Components/newRegisrationPage/newRegistration";
import LoginPage from "./Components/entrancePage/login";

// Component to handle external redirection
// function RedirectExternal() {
//   useEffect(() => {
//     // Redirecting to the external URL
//     window.location.href = "https://usersaudichambers.org";
//   }, []);

//   return null; // This component renders nothing as it's purely for redirection
// }

function App() {
  return (
    <Router>
      <Routes>
        {/* Redirect "/" to an external website */}
        <Route path="/" element={<HomeMain />} />
        {/* Route for showing the Verification Detail Page */}
        <Route path="/files-portal" element={<Verificationdetailpage />} />{" "}
        <Route path="/faq" element={<Faq />} />
        <Route path="/Home" element={<HomeMain />} />
        <Route path="/home" element={<HomeMain />} />
        <Route path="/about" element={<AboutUnion />} />
        <Route path="/usage-guides" element={<Guides />} />
        <Route path="/register?" element={<NewRegistration />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/contact-us" element={<ContactUs />} />
      </Routes>
    </Router>
  );
}

export default App;
