import React from "react";
import authimg from "../../images/auth-image.png";
import authbg from "../../images/auth-bg.811e30f755fa278b.png";
import companylogo from "../../images/companyLogo.png";
import bitmap from "../../images/Bitmap.svg";
import leftinput from "../../images/left-input.png";
import rightinp from "../../images/right-inp.png";
import left2 from "../../images/left-2.png";
import "./login.css";
const LoginPage = () => {
  return (
    <div style={{ display: "flex" }}>
      <div className="left " style={{ flex: 1 }}>
        <h1 className="h-1">يمكنك إدارة كل شئ تحتاجه</h1>
        <p className="-p">
          توفير الجهد و الوقت من خلال انهاء طلبات منشآتك بشكل رقمي
        </p>
        <img src={authbg} className="img-2" alt="Background" />
        <img src={authimg} className="img-1" alt="Auth" />
      </div>
      <div className="right" style={{ flex: 1 }}>
        <img src={companylogo} className="img" alt="Company Logo" />
        <h2>تسجيل الدخول</h2>
        <div className="div">
          <label htmlFor="i1">رقم الهوية</label>
          <div className="input-div">
            <img src={leftinput} alt="Left Input Icon" className="li" />
            <input
              type="text"
              id="i1"
              placeholder="ادخل رقم الهوية الخاص بك"
              className="form-control inp input-1"
            />
            <img src={rightinp} alt="Right Input Icon" className="ri" />
          </div>
        </div>
        <div className="div">
          <label htmlFor="1">الرقم السري</label>
          <div className="input-div">
            <input
              type="password"
              id="1"
              placeholder="ادخل كلمة المرور"
              className="form-control inp inp-2"
            />
            <img src={left2} alt="Left Icon" className="ri" />
          </div>
        </div>
        <div className="lower-inp">
          <div className="p1">
            <label htmlFor="tick">تذكرني</label>
            <input type="checkbox" id="tick" />
          </div>
          <span
            style={{ color: "#3F5FA0", fontWeight: 700, fontSize: "1.25rem" }}
          >
            استعادة كلمة المرور
          </span>
        </div>
        <button className="ask btn">تسجيل الدخول</button>
        <p className="p-1">
          ليس لديك حساب؟
          <span style={{ color: "#3F5FA0", fontWeight: 700 }}>
            تسجيل مستخدم جديد
          </span>
        </p>
        <div className="lower">
          <hr />
          <span className="s1">أو عن طريق</span>
          <hr />
        </div>
        <button
          type="button"
          className="ask btn abc btn-secondary btn-submit nafaz-btn"
          tabIndex="0"
        >
          النفاذ الوطني الموحد&nbsp;
          <img src={bitmap} alt="Nafaz Icon" className="me-12" />
        </button>
        <p className="p">جميع الحقوق محفوظة © 2023</p>
      </div>
    </div>
  );
};

export default LoginPage;
