import React from 'react';
import calltoactionpatren from '../../images/callToAction-pattern.2987411061646204.png'
import callToActionimage from '../../images/callToAction-image.png'
const FourthPart = () => {
  return (
    <div className="fourth-part">
      <div className="main-fourth">
        <img src={calltoactionpatren} className="lower1" alt="Pattern" />
        <div className="sect-2">
          <h1 style={{ color: 'white' }}>سارع بالتسجيل الآن لتستمتع بكل الخدمات</h1>
          <p style={{ color: 'white' }}>
            سجل معنا 400،000 منشأة حتى الآن سجل و انضم إليهم لتشاركهم التجربة الرائعة للخدمات الإلكترونية
          </p>
          <div className="lower-buttons">
            <button type="button" className="btn btn-md entrance-1 nav-but">
              دخول
            </button>
            <button type="button" className="btn entrance-2">
              تسجيل جديد
            </button>
          </div>
          <img src={callToActionimage} className="lower-img" alt="Call to Action" />
        </div>
      </div>
    </div>
  );
};

export default FourthPart;
