import React from "react";
import Navbar from "../navbar/navbar";
import Footer from "../homePage/footer";

const ContactUs = () => {
  return (
    <><Navbar/>
      <div
        className="collapse"
        id="navbarToggleExternalContent"
        data-bs-theme="dark"
      >
        <div
          className="p-4"
          style={{
            display: "flex",
            flexDirection: "column-reverse",
            textAlign: "right",
          }}
        >
          <form action="/login" method="GET">
            <button className="btn btn-md entrance nav-but">دخول</button>
          </form>
          <br />
          <form action="/register" method="GET">
            <button className="btn btn-primary new-reg nav-but">
              {" "}
              تسجيل جديد{" "}
            </button>
          </form>
          <a href="/contact-us" className="nav-anc">
            اتصل بنا
          </a>
          <a href="/faq" className="nav-anc">
            الأسئلة الشائعة{" "}
          </a>
          <a href="/usage-guides" className="nav-anc">
            أدلة الإستخدام
          </a>
          <a href="/about" className="nav-anc">
            عن المنصة
          </a>
          <a href="/home" className="nav-anc">
            الرئيسية
          </a>
        </div>
      </div>

      <main>
        <div className="upper-header">
          <h1>اتصل بنا</h1>
          <div className="path">
            <p>
              <b>الرئيسية</b> اتصل بنا
            </p>
          </div>
        </div>

        <form action="">
          <div className="row">
            <div className="col form-group" style={{ textAlign: "right" }}>
              <label className="label required" style={{ textAlign: "right" }}>
                الاسم
              </label>
              <input
                type="text"
                placeholder="أدخل الاسم"
                className="form-control"
                style={{ textAlign: "right", backgroundColor: "#eeeeef" }}
              />
            </div>

            <div className="col form-group select-box">
              <label className="label required">الغرفة التجارية</label>
              <select
                className="form-control"
                style={{ backgroundColor: "#eeeeef", textAlign: "right" }}
              >
                <option value="" disabled>
                  اختر الغرفة
                </option>
                <option value="a0114355-c643-4c78-bc7e-d326595f7ade">
                  غرفة الشرقية
                </option>
                <option value="c0114355-c643-4c78-bc7e-d326595f7ade">
                  غرفة جدة
                </option>
              </select>
            </div>
          </div>

          <div className="row">
            <div className="col col-md-6">
              <div className="form-group">
                <label className="label required">البريد الإلكتروني</label>
                <input
                  type="text"
                  placeholder="email@email.com"
                  className="form-control"
                  style={{ backgroundColor: "#eeeeef" }}
                />
              </div>
            </div>

            <div className="col form-group has-icon">
              <label className="label required">رقم الجوال</label>
              <input
                type="text"
                placeholder="+9665xxxxxxxxx"
                className="form-control"
                style={{ backgroundColor: "#eeeeef" }}
              />
            </div>
          </div>

          <div className="form-group">
            <label className="label required">الوصف</label>
            <textarea
              placeholder="ادخل الوصف"
              rows="4"
              className="form-control"
              style={{
                backgroundColor: "#eeeeef",
                marginLeft: "1.5rem",
                textAlign: "right",
              }}
            ></textarea>
          </div>

          <div className="row">
            <div className="form-group">
              <label className="label">المرفقات</label>
              <input
                type="file"
                accept="application/pdf,image/png,image/jpeg"
                className="form-control"
                style={{ backgroundColor: "#eeeeef", marginLeft: "-0.5rem" }}
              />
            </div>
          </div>

          <button className="ask btn">إرسال</button>
        </form>
      </main>

    <Footer/>
    </>
  );
};

export default ContactUs;
