import React from "react";
import Navbar from "../navbar/navbar";
import Footer from "../homePage/footer";
import "./about.css";
const AboutUnion = () => {
  return (
    <div>
      <Navbar />
      {/* Bootstrap script can be loaded in index.html or another entry point file */}
      <main>
        <div className="about-upper-header">
          <h1>عن إتحاد الغرف</h1>
          <div className="about-path">
            <p>
              <b>الرئيسية</b> عن إتحاد الغرف
              <i className="fa-sharp fa-solid fa-house"></i>
            </p>
          </div>
        </div>

        <div className="about-main-content">
          <h2>عن إتحاد الغرف التجارية</h2>
          <p>
            في إطار سعي الهيئة والغرف لتحقيق استراتيجية التحول الرقمي الموحد في
            تقديم خدمات الغرف الذكية والتي تهدف
            <b>
              {" "}
              لتوفير خدمات نوعية لمشتركي الغرف السعودية وتعزز من رضا العميل{" "}
            </b>
            وتزيد مشاركة مشتركي الغرف في تطوير سياستها بشكل عام ومن تطوير
            السياسات تجاه تحسين الخدمات المقدمة بشكل خاص
          </p>

          <h6>
            <b>خدمات الغرف الذكية تهدف لتحقيق عدة محاور:</b>
          </h6>

          <ul className="about-list">
            <li>التمحور حول الـمستفيد.</li>
            <li>إرساء مبدأ الشفافية.</li>
            <li>تحسين جودة إجراءات العمل وتبسيطها.</li>
          </ul>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default AboutUnion;
